import AuthForm from "../components/AuthForm";
import { json, redirect } from 'react-router-dom';
import washingtonList from "../resources/WashingtonCities.json"

function AuthenticationPage() {
  return <AuthForm />;
}

export default AuthenticationPage;


export async function action({ request }) {
  const searchParams = new URL(request.url).searchParams;
  const mode = searchParams.get('mode') || 'login';
  if (mode !== 'login' && mode !== 'signup') {
    throw json({ message: 'Unsupported mode.' }, { status: 422 });
  }

  let formData = await request.formData();

  const loginData = {
    email: formData.get('email').toLowerCase(),
    password: formData.get('password'),
  };
  formData.set("email", loginData.email);
  let response;
  if(mode === "signup"){
    const cityName= formData.get('city');
    const matchingCities = washingtonList.filter(city => city.Location.toLowerCase().includes(cityName.toLowerCase()));
    if (matchingCities.length > 0) {
      const lat = matchingCities[0].Latitude;
      const lng = matchingCities[0].Longitude;
      formData.append('location[lat]', lat);
      formData.append('location[lng]', lng);
    } else {
      throw json({ message: 'Could not find City location' }, { status: 409 });
    }
      response = await fetch(process.env.REACT_APP_BACKEND_URL+'signup', {
      method: 'POST',
      body: formData,
    });
  }else{
      response = await fetch(process.env.REACT_APP_BACKEND_URL+'login', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(loginData),
    });
  }

  if (response.status === 400) {
    return response;
  }

  if (!response.ok) {
    throw json({ message: 'Could not authenticate user.' }, { status: 500 });
  }
  try {
    const resData = await response.json();
    // Handle successful response data
    const token = resData.token;
    localStorage.setItem('token', token);
    const expiration = new Date();
    expiration.setHours(expiration.getHours() + 1);
    localStorage.setItem('expiration', expiration.toISOString());

  } catch (error) {
    console.error('Error parsing JSON response:', error);
    // Handle JSON parsing error
  }


  return redirect('/myProfile');
}
 