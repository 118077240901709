import "./AboutUs.css"
import figurine7 from "../resources/images/figurine7.jpg"
import figurine5 from "../resources/images/figurine5.jpg"
import figurine4 from "../resources/images/figurine4.jpg"
import logo from "../resources/images/bcmsBlackLogo.png"
export default function AboutUs(){
    return(
        <>
        <section>
            <div className="aboutUs">
                <div>
                    <h1>Welcome to The Bellingham Chamber Music Society’s MeetUp space!</h1>                    
                </div>
                <img className="bannerImage" src={figurine7} alt="Banner Firgurines"/>
                <div>
                    <p>This space is designed for people who enjoy playing chamber music and are interested in finding others with whom they can collaborate. </p>
                </div>
                <div class="container">
                <div class="left-photo">
                    <img src={figurine5} alt="Left Figurines"/>
                </div>
                <div class="center-content">
                    <ul className="listItems">
                        <li>Whether you want to rehearse weekly, get together only occasionally, prepare performances, receive coachings…</li>
                        <li>Whether you are a college age student, adult amateur, novice, ex-pro, play regularly, or have to dust off your instrument…. </li>
                        <li>…we hope you can use this site to find like minded people to collaborate with.</li>
                    </ul>
                </div>
                <div class="right-photo">
                    <img src={figurine4} alt="Right Figurines"/>
                </div>
                </div>

                <div>                    
                    <p>BCMS is founded in the love of music and the joy that comes from playing and sharing chamber music with others. The collaborative process enriches us as musicians.</p> 
                    <p>We are happy to offer coaching sessions with any of our performing artists listed here. You may contact any of our artists independently to book coaching sessions. </p>
                    <p>Certainly be in touch with us and let us know what you are up to. We find magic in chamber music and would love to hear your experiences as they unfold. </p>
                </div>
                <img src={logo} alt="BCMS" className="logoImage"/>
            </div>
        </section>
        </>
    )
}