import { Suspense } from 'react';
import {
  useRouteLoaderData,
  json,
  defer,
  Await,
} from 'react-router-dom';

import { getAuthToken } from '../util/auth';
import MusicianContent from '../components/MusicianContent';

function MusicianDetail() {
  const { musician } = useRouteLoaderData('musician-detail');

  return (
    <>
      <Suspense fallback={<p style={{ textAlign: 'center' }}>Loading...</p>}>
        <Await resolve={musician}>
          {(loadedEvent) => <MusicianContent profileDetails={loadedEvent} />}
        </Await>
      </Suspense>
    </>
  );
}

export default MusicianDetail;

async function loadEvent(id) {
  const token = getAuthToken();
  const response = await fetch(process.env.REACT_APP_BACKEND_URL+'profiles/' + id, {
    method: 'GET',
    headers: {
      'Authorization': 'Bearer ' + token 
    }
  });
  if (!response.ok) {
    throw json(
      { message: 'Could not fetch details for selected musician.' },
      {
        status: 500,
      }
    );
  } else if(response.status === 401){
    return response.statusText;
  }else{
    const resData = await response.json();
    return resData;
  }
}

export async function loader({ params }) {
  const id = params.musicianId;
  return defer({
    musician: await loadEvent(id),
  });
}


