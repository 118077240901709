import "./HowTo.css"
import logo from "../resources/images/GreenBlueLogo.png"
export default function HowTo(){
    return(
        <>
        <section>
            <div className="howTo">
                <div className="intro">
                    <h1>What is This?</h1>
                    <p>The BCMS Classical Music MeetUp site is simply a space for people who love chamber music  to get connected with others in our community to share the joy of making music together. You will post basic information about your instrument, playing level, availability etc and then search for others that match your chamber music goals.  </p>  
                </div>
                <div className="explain">
                    <h1>How Do I get involved?</h1>
                    <h3>Start By making an account:</h3>
                    <ul>
                        <li>Press log-in from the navigation bar</li>
                        <li> Fill in the required information, read the site rules and hit submit!</li>
                        <li> You will be redirected to your profile page where you can add as much additional information you like!</li>
                        <li>The more information, the more people with like-minded chamber goals can find you!</li> 
                        <li>Make sure your account is active if you want others to be able to see it</li>
                        <li>Avoid putting personal information(phone number, address, ect)</li>
                    </ul>
                    <h3>I have an account, now what?:</h3>
                    <ul>
                        <li>Navigate over to the 'Musician Profiles' tab</li>
                        <li>Scroll Through the accounts and click on any you want to read more about</li>
                        <li>You can use the different search options to narrow down profiles by what type of player you are looking for,
                            Note: the search options are based off of active profiles, if you don't see what you are looking for in the search it's because 
                            there are not active profiles with the specified search
                        </li>
                    </ul>
                    <p>For any issues or questions, Please contact: musicinbellingham@gmail.com</p>
                </div>
                <img className="logoImage" src={logo} alt="BCMS"/>
            </div>
        </section>
        </>
    )
}