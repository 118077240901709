import classes from './ProfilesList.module.css';
import Search from './Search';
import UserItem from './UserItem';
import { useState } from 'react';
import { useEffect } from 'react';
function ProfilesList({profiles, currentProfile}) {
  const [citySearchTerm, setCitySearchTerm] = useState("");
  const [instrumentSearchTerm, setinstrumentSearchTerm] = useState("");
  const [interestSearchTerm, setInterestSearchTerm] = useState("");
  const [musicSearchTerm, setMusicSearchTerm] = useState("");
  const [lookingSearchTerm, setLookingSearchTerm] = useState("");
  const [playingLevelSearchTerm, setPlayingLevelSearchTerm] = useState("");
  const [frequencySearchTerm, setFrequencySearchTerm] = useState("");
  const [showAdvancedSearch, setShowAdvancedSearch] = useState(false);
  let [filteredProfiles, setFilteredProfiles] = useState([]);
  
  const playingLevelOptions = [
    { value: 'professional', label: 'Professional' },
    { value: 'advanced', label: 'Advanced' },
    { value: 'intermediate', label: 'Intermediate' },
    { value: 'beginner', label: 'Beginner' }
  ]

  const frequencyOptions = [
    {value: 'weekly', label:'Weekly or more'},
    {value: 'biweekly', label:'Every other week'},
    {value: 'monthly', label:'Once a month'},
    {value: 'occasionally', label:'Every once in a while'}
  ]
 const uniqueCitiesSet = new Set();
 profiles.forEach((profile) => {
   // Check if the city already exists in the set based on its label
   const cityExists = Array.from(uniqueCitiesSet).some((city) => city.label === profile.city);
   if (!cityExists) {
     uniqueCitiesSet.add({
       label: profile.city,
       value: profile.location,
     });
   }
 });
 
 let cities = Array.from(uniqueCitiesSet);
 
  
  const uniqueInstrumentSet = new Set();
  profiles.forEach((profile) => {
    profile.instrument.forEach((inst)=>{
      uniqueInstrumentSet.add(inst.toLowerCase());
    })
  });
  let instruments = Array.from(uniqueInstrumentSet);

  instruments = instruments.map((inst) => ({
    value: inst,
    label: inst
  }))

  const uniqueInterestSet = new Set();
  profiles.forEach((profile) => {
    profile.interestedIn.forEach((inst)=>{
      uniqueInterestSet.add(inst);
    })
  });
  let interests = Array.from(uniqueInterestSet);

  interests = interests.map((inst) => ({
    value: inst,
    label: inst
  }))

  const uniqueMusicSet = new Set();
  profiles.forEach((profile) => {
    profile.musicStyle.forEach((inst)=>{
      uniqueMusicSet.add(inst);
    })
  });

  let musicStyle = Array.from(uniqueMusicSet);

  musicStyle = musicStyle.map((inst) => ({
    value: inst,
    label: inst
  }))
  
  const uniqueLookingSet = new Set();
  profiles.forEach((profile) => {
    profile.lookingFor.forEach((inst)=>{
      uniqueLookingSet.add(inst);
    })
  });

  let lookingFor = Array.from(uniqueLookingSet);

  lookingFor = lookingFor.map((inst) => ({
    value: inst,
    label: inst
  }))

  const handleInstrumentSearchChange = (newValue) => {
    setinstrumentSearchTerm(newValue); // Update instrument search term state
    filterProfiles(); // Trigger filtering
  };
  
  // Event handler for interest search change
  const handleInterestSearchChange = (newValue) => {
    setInterestSearchTerm(newValue); // Update interest search term state
    filterProfiles(); // Trigger filtering
  };

  const handleMusicSearchChange = (newValue) => {
    setMusicSearchTerm(newValue); // Update interest search term state
    filterProfiles(); // Trigger filtering
  };

  const handleLookingSearchChange = (newValue) => {
    setLookingSearchTerm(newValue); // Update interest search term state
    filterProfiles(); // Trigger filtering
  };

  const handleCitySearchChange = (newValue) => {
    setCitySearchTerm(newValue); // Update interest search term state
  };

  const handleFrequencySearchChange = (newValue) => {
    setFrequencySearchTerm(newValue); // Update interest search term state
    filterProfiles(); // Trigger filtering
  };

  const handlePlayingLevelSearchChange = (newValue) => {
    setPlayingLevelSearchTerm(newValue); // Update interest search term state
    filterProfiles(); // Trigger filtering
  };

  
  const toggleAdvancedSearch = () => {
    setShowAdvancedSearch(!showAdvancedSearch);
  };

  useEffect(() => {
    setFilteredProfiles(profiles.filter(profile => profile._id !== currentProfile._id && profile.isActive !== false));
  }, [profiles, currentProfile]);

  useEffect(() => {
    filterProfiles(); // Trigger filtering whenever search terms change
  }, [instrumentSearchTerm, interestSearchTerm, musicSearchTerm, lookingSearchTerm, playingLevelSearchTerm, frequencySearchTerm]);
  
  useEffect(() => {
    if (citySearchTerm !== "") {
      reorderProfilesByDistance(); 
    }
  }, [citySearchTerm]);

  const reorderProfilesByDistance = () => {
    const distances = filteredProfiles.map(profile => {
      const targetCity = citySearchTerm ? citySearchTerm.value : currentProfile.city;
      const distance = calculateDistance(
        profile.location.lat,
        profile.location.lng,
        targetCity.lat,
        targetCity.lng
      );
      return { profile, distance };
    });

    distances.sort((a, b) => a.distance - b.distance);
    const reorderedProfiles = distances.map(item => item.profile);
    setFilteredProfiles(reorderedProfiles);
  };

  const filterProfiles = () => {
    let filtered = profiles.filter(profile => profile._id !== currentProfile._id);

    if (frequencySearchTerm.length > 0) {
      filtered = filtered.filter(profile =>
        frequencySearchTerm.some(term =>
          profile.frequency === term.value
        )
      );
    }

    if (playingLevelSearchTerm.length > 0) {
      filtered = filtered.filter(profile =>
        playingLevelSearchTerm.some(term =>
          profile.playingLevel === term.value
        )
      );
    }

    if (instrumentSearchTerm.length > 0) {
      filtered = filtered.filter(profile =>
        profile.instrument.some(inst =>
          instrumentSearchTerm.some(term => inst.toLowerCase() === term.value.toLowerCase())
        )
      );
    }
  
    if (interestSearchTerm.length > 0) {
      filtered = filtered.filter(profile =>
        profile.interestedIn.some(interest =>
          interestSearchTerm.some(term => interest.toLowerCase() === term.value.toLowerCase())
        )
      );
    }
  
    if (musicSearchTerm.length > 0) {
      filtered = filtered.filter(profile =>
        profile.musicStyle.some(style =>
          musicSearchTerm.some(term => style.toLowerCase() === term.value.toLowerCase())
        )
      );
    }
  
    if (lookingSearchTerm.length > 0) {
      filtered = filtered.filter(profile =>
        profile.lookingFor.some(looking =>
          lookingSearchTerm.some(term => looking.toLowerCase() === term.value.toLowerCase())
        )
      );
    }
  
    setFilteredProfiles(filtered);
  };
  
  return (<>
<div className={classes.searchContainer}>
  <h2>Filter Profiles</h2>
  <h4>*search lists dependent on active profiles*</h4>
  <div className={classes.search}>
    <ul className={classes.searchList}>
      <li className={classes.searchItem}>
        <Search title="Order By Distance From" searchTerm={citySearchTerm} onSearchChange={handleCitySearchChange} options={cities} multi={false} />
      </li>
      <li className={classes.searchItem}>
        <Search title="Instruments Played" searchTerm={instrumentSearchTerm} onSearchChange={handleInstrumentSearchChange} options={instruments} multi={true} />
      </li>
      <li className={classes.searchItem}>
        <Search title="Playing Level" searchTerm={lookingSearchTerm} onSearchChange={handlePlayingLevelSearchChange} options={playingLevelOptions} multi={true} />
      </li>
    </ul>
    <button onClick={toggleAdvancedSearch} className={classes.toggleButton}>
      {showAdvancedSearch ? 'Hide Advanced Search' : 'Show Advanced Search'}
    </button>
  </div>
  <div className={`${classes.advancedSearch} ${showAdvancedSearch ? classes.show : ''}`}>
    {showAdvancedSearch && (
      <ul className={classes.searchList}>
        <li>
          <Search title="Interested In" searchTerm={interestSearchTerm} onSearchChange={handleInterestSearchChange} options={interests} multi={true} />
        </li>
        <li>
          <Search title="Music Style" searchTerm={musicSearchTerm} onSearchChange={handleMusicSearchChange} options={musicStyle} multi={true} />
        </li>
        <li>
          <Search title="Looking For" searchTerm={lookingSearchTerm} onSearchChange={handleLookingSearchChange} options={lookingFor} multi={true} />
        </li>
        <li>
          <Search title="Frequency of Meeting" searchTerm={lookingSearchTerm} onSearchChange={handleFrequencySearchChange} options={frequencyOptions} multi={true} />
        </li>
      </ul>
    )}
  </div>
</div>

    <div className={classes.profiles}>       
        {!profiles ?  <p>Profiles could not be retrieved</p>:filteredProfiles.map((profile) => (
          <ul className={classes.list} key={profile._id}>
          <UserItem
          id={profile._id}
          image={profile.image}
          name={profile.name}
          instrument={profile.instrument}
          city={profile.city}
          playingLevel={profile.playingLevel}
          />
          </ul>
        ))}
    </div>
    </>
  );
}

export default ProfilesList;


function calculateDistance(lat1, lon1, lat2, lon2) {
  const earthRadiusKm = 6371; // Radius of the Earth in kilometers
  const dLat = degreesToRadians(lat2 - lat1);
  const dLon = degreesToRadians(lon2 - lon1);
  const a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(degreesToRadians(lat1)) * Math.cos(degreesToRadians(lat2)) *
    Math.sin(dLon / 2) * Math.sin(dLon / 2);
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  const distance = earthRadiusKm * c; // Distance in kilometers
  return distance;
}

function degreesToRadians(degrees) {
  return degrees * (Math.PI / 180);
}
