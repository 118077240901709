import { json, redirect } from 'react-router-dom';
import ResetPasswordForm from "../components/ResetPasswordForm";

function ResetPassword() {
  return <ResetPasswordForm />;
}

export default ResetPassword;


export async function action({ request }) {
    let formData = await request.formData();
    const token = window.location.pathname.split("/").pop();
    const passwordData = {
        password: formData.get('password')
    };

    let response;
    response = await fetch(process.env.REACT_APP_BACKEND_URL+`reset-Password/${token}`, {
    method: 'POST',
    headers: {
    'Content-Type': 'application/json',
    },
    body: JSON.stringify(passwordData),
    });
  

  if (response.status === 400) {
    return response;
  }

  if (!response.ok) {
    throw json({ message: 'Could not authenticate user.' }, { status: 500 });
  }

  return redirect('/auth');
}
 