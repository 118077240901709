import React from 'react';
import { Link } from 'react-router-dom';

import Avatar from './shared/Avatar';
import './UserItem.css';

const UserItem = props => {
  return (
    <li  className="user-item">
        <Link to={`/musicians/${props.id}`}>
          <div className="user-item__image">
            <Avatar image={props.image} alt={props.name} width={"100px"}/>
          </div>
          <ul key={props._id} className="user-item__info">
            <li><h1>{props.name}</h1></li>
            <li><h3>{props.playingLevel}</h3></li>
            {props.instrument.map((inst)=>(
                <li><h3>{inst}</h3></li>
            ))}
            <li><h3>{props.city}</h3></li>
          </ul>
        </Link>
    </li>
  );
};

export default UserItem;
