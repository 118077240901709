import React, { useState } from 'react';
import Card from '../components/shared/UIElements/Card';
import Input from '../components/shared/FormElements/Input';
import Button from '../components/shared/FormElements/Button';
import ErrorModal from '../components/shared/UIElements/ErrorModal';
import ImageUpload from '../components/shared/FormElements/ImageUpload';
import LoadingSpinner from '../components/shared/UIElements/LoadingSpinner';
import {
  VALIDATOR_EMAIL,
  VALIDATOR_MINLENGTH,
  VALIDATOR_REQUIRE
} from '../components/shared/util/validators';
import { useForm } from '../components/shared/FormElements/hooks/form-hook';
import { useHttpClient } from '../components/shared/FormElements/hooks/http-hook';
import '../pages/Auth.css';
import Select from 'react-select';
import { Form, Link, useSearchParams, useActionData, useNavigation} from 'react-router-dom';
import CreatableSelect from 'react-select/creatable';
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';


const AuthForm = () => {
  const navigation = useNavigation();
  const isSubmitting = navigation.state === 'submitting';
  const data = useActionData();
  const [searchParams] = useSearchParams();
  const [location] = useState('location');
  const isLogin = searchParams.get('mode') === 'login';
  const [isLoginMode, setIsLoginMode] = useState(true);
  const { isLoading, error, clearError } = useHttpClient();
  const options = [
    { value: 'professional', label: 'Professional' },
    { value: 'advanced', label: 'Advanced' },
    { value: 'intermediate', label: 'Intermediate' },
    { value: 'beginner', label: 'Beginner' }

  ]
  const [formState, inputHandler, setFormData] = useForm(
    {
      email: {
        value: '',
        isValid: false
      },
      password: {
        value: '',
        isValid: false
      }
    },
    false
  );
  const switchModeHandler = () => {
    if (!isLoginMode) {
      setFormData(
        {
          ...formState.inputs,
          name: undefined,
          image: undefined,
          instrument: undefined,
          city: undefined,
          playingLevel: undefined
        },
        formState.inputs.email.isValid && formState.inputs.password.isValid
      );
    } else {
      setFormData(
        {
          ...formState.inputs,
          name: {
            value: '',
            isValid: false
          },
          image: {
            value: null,
            isValid: false
          },
          instrument:{
            value: '',
            isValid: false
          },
          city:{
            value: '',
            isValid: false
          },
          playingLevel:{
            value: '',
            isValid: false
          },
        },
        false
      );
    }
    setIsLoginMode(prevMode => !prevMode);
  };

  return (
    <>
      <ErrorModal error={error} onClear={clearError} />
      <Card className="authentication">
      {isLoading && <LoadingSpinner asOverlay />}
      <h2>{isLoginMode ? 'LOGIN' : 'SIGNUP'}</h2>
        <hr />
        <Form method="post" encType="multipart/form-data" >
        {data && data.errors && <ul>
          {Object.values(data.errors).map((err)=>(
            <li key={err}>{err}</li>
          ))}
          </ul>}
        {data && data.message && <p>{data.message}</p>}
          {!isLoginMode && (
            <Input
              element="input"
              id="name"
              type="text"
              name="name"
              label="Your Name"
              validators={[VALIDATOR_REQUIRE()]}
              errorText="Please enter a name."
              onInput={inputHandler}
            />
          )}
          {!isLoginMode && (<div>
            <h4>Type Instrument(s):</h4>
            <CreatableSelect isClearable isMulti 
              element="select"
              id="instrument"       
              type="text"     
              name='instrument' 
              errorText="Please enter your instrument."
              validators={[VALIDATOR_REQUIRE()]}
              onChange={(selectedOption) =>
              inputHandler("instrument", selectedOption[0], true)}
          />
            </div>
          )}
          {!isLoginMode && (
            <Input
              element="input"
              id="city"
              name="city"
              type="text"
              label="What City in Washington State do you reside in"
              validators={[VALIDATOR_REQUIRE()]}
              default = {location}
              errorText="Please enter your city."
              onInput={inputHandler}
            />
          )}
          {!isLoginMode && (<div>
            <h4>Playing Level:</h4>
            <Select
              element="select"
              id="Playing Level"
              name="playingLevel"
              type="text"
              validators={[VALIDATOR_REQUIRE()]}
              options={options}
              onChange={(selectedOption) =>
                inputHandler("playingLevel", selectedOption.value, true)}
            />
            </div>
          )}
          {!isLoginMode && (
            <ImageUpload center id="image" name="image" onInput={inputHandler} />
          )}
          <Input
            element="input"
            id="email"
            name="email"
            type="email"
            label="E-Mail"
            validators={[VALIDATOR_EMAIL()]}
            errorText="Please enter a valid email address."
            onInput={inputHandler}
          />
          <Input
            element="input"
            id="password"
            name="password"
            type="password"
            label="Password"
            validators={[VALIDATOR_MINLENGTH(6)]}
            errorText="Please enter a valid password, at least 6 characters."
            onInput={inputHandler}
          />
          {!isLoginMode && (
            <div>
              <Popup trigger={<Link> View Terms And Conditions</Link>} position="center" contentStyle={{ width: '50%', padding: '5%' }}>
                <div>
                The BCMS Classical Music MeetUp site is intended to create a forum for musicians within the community to find other musicians with shared interests. BCMS assumes no responsibility for individual participants involved in or activities engaged in through members or participants identified through the site. In consideration of the opportunity to participate in any activities related to advertisements or postings on the site, all participants shall be deemed for themselves and on behalf of their heirs, executors, administrators, assigns or personal representatives to waive any rights, claims or causes of action of any kind arising out of participation in activities organized through the use of the MeetUp site, and shall forever discharge BCMS for any physical or psychological injury that they may suffer as a direct result of participation in any activities organized through the MeetUp site.
                  <br/><br/>click anywhere to leave terms and Conditions
                  </div>
              </Popup>
              <p>By Clicking Submit, you agree to Terms and Services</p>
            </div>
          )}
          <Button type="submit" disabled={!formState.isValid || isSubmitting}  >
            {isSubmitting ? 'Submiting...' : 'Submit'}
          </Button>
        </Form>
        
        <Link to={`?mode=${isLogin ? 'signup' : 'login'}`}>
        <Button inverse onClick={switchModeHandler}>
          SWITCH TO {isLoginMode ? 'SIGNUP' : 'LOGIN'}
        </Button>          
        </Link>
        {isLoginMode && (
          <Link to={'../forgotPassword'} >
            <p >Forgot Password?</p>
          </Link>
        )}
      </Card>
    </>
  );
};

export default AuthForm;