import { Outlet, useLoaderData, useSubmit } from 'react-router-dom';
import { getTokenDuration } from '../util/auth';
import MainNavigation from '../components/MainNavigation';
import Footer from '../components/Footer';
import { useEffect } from 'react';

function RootLayout() {
  const token = useLoaderData();
  const submit = useSubmit();

  useEffect(()=>{
    if(!token){
      return;
    }
    if(token === 'Expired'){
      submit(null, {action: '/logout', method: 'post'})
    }

    const tokenDuration = getTokenDuration();
    setTimeout(() =>{
      submit(null, {action: '/logout', method: 'post'})
    }, tokenDuration);
  }, [token, submit]);
  return (
    <>
      <MainNavigation />
      <main>
        <Outlet />
      </main>
      <Footer/>
    </>
  );
}

export default RootLayout;
