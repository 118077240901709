import { Form, Link, NavLink, useRouteLoaderData } from 'react-router-dom';

import classes from './Footer.module.css';

function Footer() {
  const token = useRouteLoaderData('root');
  return (<>
    <footer className={classes.footer}>
        <div>Contact Us: musicinbellingham@gmail.com</div>
      </footer>    
    </>
  );
}

export default Footer;
