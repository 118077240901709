import { RouterProvider, createBrowserRouter } from 'react-router-dom';

import ErrorPage from './pages/Error';
import MusicianDetailPage, {
  loader as musicianDetailLoader
} from './pages/MusicianDetail';
import MusiciansPage, { loader as musicianLoader } from './pages/Musicians';
import ProfilesRootLayout from './pages/ProfilesRoot';
import HomePage from './pages/Home';
import RootLayout from './pages/Root';
import AuthenticationPage, {action as authAction} from './pages/Authentication'
import {action as logoutAction} from './pages/Logout'
import {action as updateProfileAction } from './pages/MyProfile'
import {action as forgetPasswordAction } from './pages/ForgotPassword'
import { action as resetPasswordAction } from './pages/ResetPassword'
import { tokenLoader } from './util/auth';
import MyProfile, {loader as myProfileLoader} from './pages/MyProfile';
import AboutUs from './pages/AboutUs';
import HowTo from './pages/HowTo';
import Resources from './pages/Resources';
import ForgotPassword from './pages/ForgotPassword';
import ResetPassword from './pages/ResetPassword';

const router = createBrowserRouter([
  {
    path: '/',
    element: <RootLayout />,
    errorElement: <ErrorPage />,
    id: 'root', 
    loader: tokenLoader,
    children: [
      { index: true, element: <HomePage /> },
      {
        path: 'musicians',
        element: <ProfilesRootLayout />,
        children: [
          {
            index: true,
            element: <MusiciansPage />,
            loader: musicianLoader,
          },
          {
            path: ':musicianId',
            id: 'musician-detail',
            element: <MusicianDetailPage/>,
            loader: musicianDetailLoader,
          },
        ],
      },
      {
        path: 'aboutUs',
        element: <AboutUs/>,
      },
      {
        path: 'Resources',
        element: <Resources/>,
      },
      {
        path: 'howTo',
        element: <HowTo/>,
      },
      {
        path: 'myProfile',
        element: <MyProfile/>,
        loader: myProfileLoader,
        action: updateProfileAction
      },
      {
        path: 'auth',
        element: <AuthenticationPage/>,
        action: authAction,
      },
      {
        path: 'forgotPassword',
        element: <ForgotPassword/>,
        action: forgetPasswordAction
      },
      {
        path:'resetPassword/:token',
        element: <ResetPassword/>,
        action: resetPasswordAction,
      },
      {
        path: 'logout',
        action: logoutAction,
      },
    ],
  },
]);

function App() {
  return <RouterProvider router={router} />;
}

export default App;
