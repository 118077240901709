import Card from "./shared/Card";
import "./MeetUpResources.css"
import { Link } from "react-router-dom";

const MeetUpResources = ()=> {
    return(        
    <>
        <div className="resources">
            <h2>Free Resources</h2>
            <h3>All Musicians</h3>
            <ul className="list">
                <li>
                    <Card style={{ width: '350px', height: '300px'  }}>
                        <a href="https://imslp.org/wiki/Main_Page"><h3>IMSLP</h3></a>
                        <p>International Music Score Library Project. Learn more at: <a href="https://en.wikipedia.org/wiki/International_Music_Score_Library_Project"> About IMSLP</a></p>
                    </Card>
                </li>
                <li>
                    <Card style={{ width: '350px', height: '300px' }}>
                        <a href="https://library.wwu.edu/music-library-home"><h3>Western Washington University</h3></a>
                        <p>Scroll to middle of the page to find “All of the Music Library's holdings are accessible via OneSearch - The WWU Online Catalog”
Western Libraries is open to visitors. Residents of Whatcom County may check out materials as part of the Whatcom Libraries Collaborate program.  Anyone is welcome to access our collections during regular business hours. </p>
                    </Card>
                </li>
            </ul>
        </div>

        <div className="resources1">
            <h2>For Purchase</h2>
            <h3>All Musicians</h3>
            <ul className="list">
                <li>
                    <Card style={{width:'325px', height:'300px'}}>
                       <a href="https://www.grothmusic.com/c-860-chamber-ensembles.aspx"> <h3>Groth Music</h3></a>
                        <p>Groth Music is a great site with the ability to find chamber music by selecting specific instrument combinations and to easily cross filter for BIPOC, Latinx, or Women composers. Learn more at: <br/><a href="https://www.grothmusic.com/t-about.aspx">About Groth.</a></p>
                    </Card>
                </li>
                <li>
                    <Card style={{width:'325px', height:'300px'}}>
                    <a href="https://www.stantons.com/"> <h3>Stanton's Sheet Music</h3></a>
                        <p>Stanton’s has lots of options for strings and winds in various categories based on levels and music type.
                        Learn more at: <br/><a href="https://www.stantons.com/sheet-music/about-us">About Stanton's Sheet Music.</a>
                        </p>
                    </Card>
                </li>
                </ul>
                <h3>Double Reed Focus</h3>
                <ul className="list">
                <li>
                    <Card style={{width:'325px', height:'300px'}}>
                    <a href="https://www.trevcomusic.com/"> <h3>Trevco Music</h3></a>
                        <p>Trevco is a great site with the ability to find music by selecting specific instrument combinations.
                        Learn more at: <br/><a href="https://www.trevcomusic.com/pages/about-us">About Trevco Music.</a>
                        </p>
                    </Card>
                </li>
                </ul>
                <h3>String Focus</h3>
                <ul className="list">
                <li>
                    <Card style={{width:'325px', height:'300px'}}>
                    <a href="https://www.sharmusic.com"> <h3>Shar Music</h3></a>
                    <p>String instrumentation for various instrumentation.</p>
                    </Card>
                </li>
                <li>
                    <Card style={{width:'325px', height:'300px'}}>
                    <a href="https://www.johnsonstring.com/catalog/sheet-music-and-books/"> <h3>Johnson String Instrument</h3></a>
                        <p>String instrumentation for various instrumentation.</p>
                    </Card>
                </li>
            </ul>
        </div>
        <div className='resources2'>
            <h2>BCMS Chamber Music Coaches</h2>
            <p>Performing Artists from the Bellingham Chamber Music Society are available for coaching your small ensembles. These musicians have years of experience as professional players, teachers, and coaches. They have performed as orchestral players in symphonies, operas, ballets and of course, they have played tons of chamber music. 
                 If you are interested in reaching out to a coach you can read their bios at </p><a href="https://musicinbellingham.com/artists/" style={{color:"white"}}>https://musicinbellingham.com/artists/</a> <p> and contact them directly to discuss options. Each musician sets their own rate independently of the Bellingham Chamber Music Society. </p>
            <h3>Strings</h3>
            <ul className="list">
                <li>
                    <Card style={{width:'325px', height:'200px'}}>
                        <h3>Lenelle Morse</h3>
                        <p>Instrument: Violin</p>
                        <p>Contact: lenelle.morse@gmail.com</p>

                    </Card>
                </li>
                <li>
                    <Card style={{width:'325px', height:'200px'}}>
                        <h3>Yuko Watanabe</h3>
                        <p>Instrument: Violin</p>
                        <p>Contact: yuko.king@gmail.com</p>
                    </Card>
                </li>
                <li>
                    <Card style={{width:'325px', height:'200px'}}>
                        <h3>Carolyn Canfield</h3>
                        <p>Instrument: Violin</p>
                        <p>Contact: carocole55@gmail.com</p>
                    </Card>
                </li>
                <li>
                    <Card style={{width:'325px', height:'200px'}}>
                        <h3>Samantha Sinai</h3>
                        <p>Instrument: Cello</p>
                        <p>Contact: samanthasinai@gmail.com</p>
                    </Card>
                </li>
                </ul>
                <h3>Winds</h3>
                <ul className="list">
                    <li>
                        <Card style={{width:'325px', height:'200px'}}>
                            <h3>Mehrdad Golami</h3>
                            <p>Instrument: Flute</p>
                            <p>Contact: gholamm@wwu.edu</p>
                        </Card>
                    </li>
                    <li>
                        <Card style={{width:'325px', height:'200px'}}>
                            <h3>Jennifer Weeks</h3>
                            <p>Instrument: Oboe</p>
                            <p>Contact: jenniferweeks00@gmail.com</p>
                        </Card>
                    </li>
                    <li>
                        <Card style={{width:'325px', height:'200px'}}>
                            <h3>Pat Nelson</h3>
                            <p>Instrument: Bassoon</p>
                            <p>Contact: patbsn@gmail.com</p>
                        </Card>
                    </li>
                </ul>
                <h2>Voice</h2>
                <ul className="list">
                    <li>
                        <Card style={{width:'300px', height:'200px'}}>
                            <h3>Heather Dudenbostal</h3>
                            <p>Instrument: Voice</p>
                            <p>Contact: meagheh@wwu.edu</p>
                        </Card>
                    </li>
                </ul>
                <br/>
                <br/>
        </div>
        <div className="resources3">
            <h2>Rehearsal Spaces For Rent</h2>
            <ul className="list">
                <li>
                    <Card style={{ width: '350px', height: '300px'  }}>
                        <a href="https://blueroombham.com/venue-info/"><h3>Blue Room</h3></a>
                        <p>202 E. Holly St. Unit 301, Bellingham, WA 98225</p>
                        <p>Email: info@blueroombham.com</p>
                        <p>Phone: (425) 289-9258</p>

                        <p>We offer our room as a rehearsal/performance space to rent any day of the week, most often available during the day 9am-5pm. Hourly rate depends on the time of day/day of the week. We have tables/chairs/full PA system and projection capabilities, as well as a beer/wine bar with soft beverage options. Get an accurate quote by reaching out to us directly!
                        </p>
                    </Card>
                </li>
                <li>
                    <Card style={{ width: '350px', height: '300px'  }}>
                        <a href="https://www.bellinghomeschoolofmusic.com"><h3>Bellinghome School of Music</h3></a>
                        <p>2512 Meridian St. Bellingham, WA. 98225</p>
                        <p>Email: Frontdesk@bellinghomeschoolofmusic.com</p>
                        <p>Phone: (360) 922-0382</p>
                        <p>We do indeed have rehearsal spaces for rent here at Bellinghome. We currently have the mornings from 10am-1pm pretty wide open, as well as Friday, evenings from 7-10pm. Weekends almost anytime are currently available as well. <a href="https://www.bellinghomeschoolofmusic.com/rehearsal-space">Rate and space details here.</a></p>
                    </Card>
                </li>
                <li>
                    <Card style={{ width: '350px', height: '300px'  }}>
                        <a href="https://www.bellingham-music.com"><h3>Bellingham Music</h3></a>
                        <p>319 E Champion St, Bellingham, WA 98225, USA</p>
                        <p>Email:  info@bellingham-music.com</p>
                        <p>Phone: (360) 676-8742</p>
                        <p>Bellingham Music is generally available for rehearsal spaceTue/Wed/Thur before 2PM and Mon/Fri before 12PM.  Weekend may be available upon request.  We have hourly rates or monthly programs with unlimited use (subject to availability).  Contact Bellingham Music for more details.</p>
                    </Card>
                </li>
            </ul>
        </div>

</>
    );
}

export default MeetUpResources;