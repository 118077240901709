import classes from './MusicianContent.module.css';
import Avatar from './shared/Avatar';
import Card from './shared/Card';

function MusicianContent({ profileDetails }) {
  return (
    <div className={classes.musicianContent}>
      <div className={classes.details}>
        <div className={classes.leftCard}>
          <Card>
            <div className={classes.avatar}>
              <Avatar image={profileDetails.image} />
            </div>
            <p>{profileDetails.city}, Washington</p>
            <h1>{profileDetails.name}</h1>
            <h3>Playing Level:</h3>
            <p>{profileDetails.playingLevel}</p>
            <h3>Instruments:</h3>
            <ul>
              {profileDetails.instrument.map(inst => (
                <li key={inst}>{inst}</li>
              ))}
            </ul>
            <h3>Contact:</h3>
            <p>{profileDetails.email}</p>
          </Card>
        </div>
        <div className={classes.rightCards}>
          <Card className={classes.bioCard}>
            <h3>Bio:</h3>
            <p>{profileDetails.bio}</p>
          </Card>
          <Card className={classes.interestCard}>
            <h3>Interested in playing for...</h3>
            <ul>
              {profileDetails.interestedIn.map(ints => (
                <li key={ints}>{ints}</li>
              ))}
            </ul>
            <h3>Prefer music styles of...</h3>
            <ul>
              {profileDetails.musicStyle.map(ints => (
                <li key={ints}>{ints}</li>
              ))}
            </ul>
            <h3>Looking for Chamber groups like...</h3>
            <ul>
              {profileDetails.lookingFor.map(ints => (
                <li key={ints}>{ints}</li>
              ))}
            </ul>
            <h3>I am hoping to rehearse...</h3>
            {profileDetails.frequency}
          </Card>
        </div>
      </div>
    </div>
  );
}

export default MusicianContent;
