import { Suspense } from 'react';
import { useLoaderData, json, defer, Await } from 'react-router-dom';
import { getAuthToken } from '../util/auth';
import ProfilesList from '../components/ProfilesList';

function MusiciansPage() {
  const { profileInfo} = useLoaderData();
  return (
    <Suspense fallback={<p style={{ textAlign: 'center' }}>Loading...</p>}>
      <Await resolve={Promise.all([profileInfo.profiles, profileInfo.myProfile])}>
        {(loadedProfiles) => {
          const [allProfiles, profile] = loadedProfiles;
          return <ProfilesList profiles={allProfiles} currentProfile={profile} />;
        }}
      </Await>
    </Suspense>
  );
}

export default MusiciansPage;

async function loadProfiles() {
  const token = getAuthToken();
  const response = await fetch(process.env.REACT_APP_BACKEND_URL+'allProfiles', {
    method: 'GET',
    headers: {
      'Authorization': 'Bearer ' + token 
    }
  });
  if(response.status === 401){
    return response;
  }
  if (!response.ok) {
    throw json(
      { message: 'Could not fetch profiles.' },
      {
        status: 500,
      }
    ); 
  } else {
    const resData = await response.json();
    return resData;
  }
}

async function loadMyProfile() {
  const token = getAuthToken();
  const response = await fetch(process.env.REACT_APP_BACKEND_URL+'myProfile', {
    method: 'GET',
    headers: {
      'Authorization': 'Bearer ' + token 
    }
  });
  if(response.status === 401){
    return response;
  }
  if (!response.ok) {
    throw json(
      { message: 'Could not fetch profiles.' },
      {
        status: 500,
      }
    ); 
  } else {
    const resData = await response.json();
    return resData;
  }
}

export function loader() {
  return defer({
    profileInfo:{
      profiles: loadProfiles(),
      myProfile: loadMyProfile()
    }
  });
}
