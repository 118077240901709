import logo from "../resources/images/GreenBlueLogo.png"
import { NavLink, useRouteLoaderData } from 'react-router-dom';
import "./HomePage.css"



function HomePage() {
  const token = useRouteLoaderData('root');
  return (
    <section className='home'>
      <div className='homeText'>
        <h5>Welcome</h5>
        <img src={logo} width='200px'/>
        <h1>Chamber Musicians</h1>
        <p>Find other Chamber Musicians, connect, and play! </p>
        {!token &&
        <NavLink to="/auth?mode=login">
          <button className="button-36">             
               Get Started
           </button>
        </NavLink>
        }
      </div>
    </section>
  );
}

export default HomePage;
