import React, { useState, useEffect } from 'react';
import ReactSwitch from 'react-switch';

function ToggleSwitch({ checked, onChange }) {
  const [isChecked, setIsChecked] = useState(checked);

  useEffect(() => {
    setIsChecked(checked);
  }, [checked]);

  const handleChange = (val) => {
    setIsChecked(val);
    onChange(val); // Propagate the value change to the parent component
  };

  return (
    <div className="app" style={{ textAlign: "center" }}>
      <h4>Keep account active?</h4>
      <p>If switched on your account will not be visible to other users</p>
      <ReactSwitch
        checked={isChecked}
        onChange={handleChange}
      />
    </div>
  );
}

export default ToggleSwitch;
