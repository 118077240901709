import { Await, defer, json, useLoaderData } from "react-router-dom";
import MyProfileForm from "../components/MyProfileForm";
import { Suspense } from "react";
import { getAuthToken } from "../util/auth";
import { redirect } from "react-router-dom";
import washingtonList from "../resources/WashingtonCities.json"

function MyProfile() {
    const { myProfile } = useLoaderData();
    return (
      <Suspense fallback={<p style={{ textAlign: 'center' }}>Loading...</p>}>
        <Await resolve={myProfile}>
          {(loadedProfile) => <MyProfileForm profile={loadedProfile} />}
        </Await>
      </Suspense>
      )
    }

export default MyProfile;

async function loadMyProfile() {
    const token = getAuthToken();

    const response = await fetch(process.env.REACT_APP_BACKEND_URL+'myProfile', {
      method: 'GET',
      headers: {
        'Authorization': 'Bearer ' + token 
      }
    });
    if(response.status === 401){
      return response;
    }
    if (!response.ok) {
      throw json(
        { message: 'Could not fetch profiles.' },
        {
          status: 500,
        }
      ); 
    } else {
      const resData = await response.json();
      return resData;
    }
  }
  
  export function loader() {
    return defer({
      myProfile: loadMyProfile(),
    });
  }

  export async function action({ request }) {
    const formData = await request.formData();
    const token = getAuthToken();
    const cityName= formData.get('city');
    const matchingCities = washingtonList.filter(city => city.Location.toLowerCase().includes(cityName.toLowerCase()));
    if (matchingCities.length > 0) {
      const lat = matchingCities[0].Latitude;
      const lng = matchingCities[0].Longitude;
      formData.append('location[lat]', lat);
      formData.append('location[lng]', lng);
    } else {
      throw json({ message: 'Could not find City location' }, { status: 409 });
    }
    let response = await fetch(process.env.REACT_APP_BACKEND_URL+'updateUser', {
        method: 'POST',
        headers: {
          'Authorization': 'Bearer ' + token 
        },
        body: formData,
      });
  
    if (response.status === 404) {
      return response;
    }
  
    if (!response.ok) {
      throw json({ message: 'Could not authenticate user.' }, { status: 500 });
    }
    return redirect('/myProfile');
  }
   