import { Form } from "react-router-dom";
import { useState } from "react";
import Select from 'react-select';

function Search({onSearchChange, options, placeholder, title, multi}){
    return (
    <div style={{ margin: '10px', alignItems: 'right', color: 'black' }}>
        <h5 style={{color:'white'}}>{title}:</h5>        
        <Select
            styles={{ color: 'black' }}
            isMulti = {multi}
            id="search"
            placeholder={placeholder}
            options={options}
            onChange={onSearchChange}
        />
    </div>

    );
}

export default Search;