import { Form, useNavigation} from 'react-router-dom';
import Input from './shared/FormElements/Input';
import {VALIDATOR_EMAIL} from '../components/shared/util/validators';
import Button from './shared/FormElements/Button';
  const ForgetPasswordForm = () => {
    const navigation = useNavigation();
    const isSubmitting = navigation.state === 'submitting';
  
    return (<>
        <Form method="post" encType="multipart/form-data" >
        <Input
            element="input"
            id="email"
            name="email"
            type="email"
            label="E-Mail"
            validators={[VALIDATOR_EMAIL()]}
            errorText="Please enter a valid email address."
        />
        <Button type="submit" disabled={ isSubmitting}>
            {isSubmitting ? 'Submiting...' : 'Request Password Reset'}
        </Button>

        </Form>

    </>);
};

export default ForgetPasswordForm;