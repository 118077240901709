import ForgetPasswordForm from "../components/ForgotPasswordForm";
import { json, redirect } from 'react-router-dom';

function ForgotPassword() {
  return <ForgetPasswordForm />;
}

export default ForgotPassword;


export async function action({ request }) {
    let formData = await request.formData();

    const emailData = {
        email: formData.get('email').toLowerCase(),
    };
    let response;
    response = await fetch(process.env.REACT_APP_BACKEND_URL+'forgetPassword', {
    method: 'POST',
    headers: {
    'Content-Type': 'application/json',
    },
    body: JSON.stringify(emailData),
    });
  

  if (response.status === 400) {
    return response;
  }

  if (response.status === 500) {
    return response;
  }
  if (!response.ok) {
    throw json({ message: 'Could not authenticate user.' }, { status: 500 });
  }

  return redirect('/auth');
}
 