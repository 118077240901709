import { Form, Link, NavLink, useRouteLoaderData } from 'react-router-dom';

import classes from './MainNavigation.module.css';

function MainNavigation() {
  const token = useRouteLoaderData('root');
  return (<>
    <header className={classes.header}>
      <div className={classes.navBar}>
        <div className={classes.logo}>BCMS Musician Meetup</div>
        <ul className={classes.list}>
        <div className={classes.listCenter}>
          <li className={classes.navSelect}>
            <NavLink
              to="/"
              className={({ isActive }) =>
                isActive ? classes.active : undefined
              }
              end
            >
              Home
            </NavLink>
          </li>
          <li className={classes.navSelect}>
            <NavLink
              to="/aboutUs"
              className={({ isActive }) =>
                isActive ? classes.active : undefined
              }
            >
              About Us
            </NavLink>
          </li>
          <li className={classes.navSelect}>
            <NavLink
              to="/resources"
              className={({ isActive }) =>
                isActive ? classes.active : undefined
              }
            >
              Resources
            </NavLink>
          </li>
          <li className={classes.navSelect}>
            <NavLink
              to="/howTo"
              className={({ isActive }) =>
                isActive ? classes.active : undefined
              }
            >
              How To
            </NavLink>
          </li>
          <li className={classes.navSelect}>
          {token &&
            <NavLink
              to="/musicians"
              className={({ isActive }) =>
                isActive ? classes.active : undefined
              }
            >
              Musician Profiles
            </NavLink>}
          </li>
          <li className={classes.navSelect}>
          {token &&
            <NavLink
              to="/myProfile"
              className={({ isActive }) =>
                isActive ? classes.active : undefined
              }
            >
              My Profile
            </NavLink>
          }
          </li>
          </div>
          <li className={classes.navSelect}>
            {!token && (
              <NavLink
              to="/auth?mode=login"
              className={classes.logIn}
            >
               Log in
            </NavLink>
            )}
            {token && (
              <Form action="/logout" method="post">
                <button>Log Out</button>
              </Form>           
            )}
          </li>
        </ul>
      </div>
    </header>
    </>
  );
}

export default MainNavigation;
