import { Form, useNavigation} from 'react-router-dom';
import Input from './shared/FormElements/Input';
import {VALIDATOR_MINLENGTH} from '../components/shared/util/validators';
import Button from './shared/FormElements/Button';
const ResetPasswordForm = () => {
    const navigation = useNavigation();
    const isSubmitting = navigation.state === 'submitting';
  
    return (<>
        <Form method="post" encType="multipart/form-data" >
        <Input
            element="input"
            id="password"
            name="password"
            type="password"
            label="Password"
            validators={[VALIDATOR_MINLENGTH(6)]}
            errorText="Please enter a valid password, at least 6 characters."
          />
        <Button type="submit" disabled={isSubmitting}>
            {isSubmitting ? 'Submiting...' : 'Resetting Password'}
        </Button>

        </Form>

    </>);
};

export default ResetPasswordForm;