import React, { useState, useEffect } from 'react';
import Card from './shared/UIElements/Card';
import Input from './shared/FormElements/Input';
import Button from './shared/FormElements/Button';
import ImageUpload from './shared/FormElements/ImageUpload';
import LoadingSpinner from './shared/UIElements/LoadingSpinner';
import CreatableSelect from 'react-select/creatable';
import {
  VALIDATOR_EMAIL,
  VALIDATOR_CITY,
  VALIDATOR_REQUIRE
} from './shared/util/validators';
import { useHttpClient } from './shared/FormElements/hooks/http-hook';
import '../pages/Auth.css';
import Select from 'react-select';
import { Form,useActionData, useNavigation} from 'react-router-dom';
import ToggleSwitch from './ToggleSwitch';


function ProfileForm({ profile }) {
  const navigation = useNavigation();
  const isSubmitting = navigation.state === 'submitting';
  const data = useActionData();
  const { isLoading, error, clearError } = useHttpClient();
  const [isFormChanged, setIsFormChanged] = useState(false);
  const [checked, setIsChecked] = useState(profile.isActive);

  const options = [
    { value: 'professional', label: 'Professional' },
    { value: 'advanced', label: 'Advanced' },
    { value: 'intermediate', label: 'Intermediate' },
    { value: 'beginner', label: 'Beginner' }
  ]

  const frequencyOptions = [
    {value: 'weekly', label:'Weekly or more'},
    {value: 'biweekly', label:'Every other week'},
    {value: 'monthly', label:'Once a month'},
    {value: 'occasionally', label:'Every once in a while'}
  ]

  let interestIdeas = [
    { value: 'fun', label: 'fun' },
    { value: 'Paid gigs', label: 'Paid gigs' },
    { value: 'performing', label: 'Performing' },
    { value: 'playing improvement', label: 'playing improvement' }
  ]
  
  let musicStyleIdeas = [
    { value: 'baroque', label: 'baroque' },
    { value: 'Romantic', label: 'Romantic' },
    { value: 'Classical', label: 'Classical' },
    { value: 'Modern', label: 'Modern' },
  ]

  let lookingForIdeas = [
    { value: 'wind duet', label: 'Wind Duet' },
    { value: 'wind trio', label: 'Wind Trio' },
    { value: 'wind quartet', label: 'Wind Quartet' },
    { value: 'wind quintet', label: 'Wind Quintet' },
    { value: 'string Duet', label: 'String Duet' },
    { value: 'string Trio', label: 'String Trio' },
    { value: 'string Quartet', label: 'String Quartet' },
    { value: 'string Quintet', label: 'String Quintet' }
  ]
  let selectIndex = options.findIndex(option => option.value === profile.playingLevel);
  let selectFreqIndex = frequencyOptions.findIndex(option => option.value === profile.frequency);

  const formattedInterested = profile.interestedIn.map(value => ({ value, label: value }));
  const formattedMusicStyle = profile.musicStyle.map(value => ({ value, label: value }));
  const formattedLookingFor = profile.lookingFor.map(value => ({ value, label: value }));
  const formattedInstruments = profile.instrument.map(value => ({ value, label: value }));

  interestIdeas = interestIdeas.filter(idea => {
    const isInFormattedArray = formattedInterested.some(formattedIdea => formattedIdea.value === idea.value);
    return !isInFormattedArray;
  });

  musicStyleIdeas = musicStyleIdeas.filter(idea => {
    const isInFormattedArray = formattedMusicStyle.some(formattedIdea => formattedIdea.value === idea.value);
    return !isInFormattedArray;
  });

  lookingForIdeas = lookingForIdeas.filter(idea => {
    const isInFormattedArray = formattedLookingFor.some(formattedIdea => formattedIdea.value === idea.value);
    return !isInFormattedArray;
  });
  useEffect(() => {
    if (isFormChanged && !isSubmitting) {
      setIsFormChanged(false); // Reset the form change state after submission
    }
  }, [isSubmitting]);

  const handleToggleChange = () => {
    setIsChecked(!checked);
    setIsFormChanged(true); // Call setIsFormChanged to indicate form changes
  };
  const handleInputChange = () => {
    setIsFormChanged(true); 
  };

  const handleFormSubmit = () => {
    setIsFormChanged(false);
  };

  return (
    <>
      <Card className="authentication">
      {isLoading && <LoadingSpinner asOverlay />}
      <h2>{profile.name}'s Profiles</h2>
        <hr />
        <Form method="post" encType="multipart/form-data" onChange={handleFormSubmit}>
        {data && data.errors && <ul>
          {Object.values(data.errors).map((err)=>(
            <li key={err}>{err}</li>
          ))}
          </ul>}
        {data && data.message && <p>{data.message}</p>}
            <ImageUpload center id="image" name="image" image={profile.image} onInput={handleInputChange} />
            <Input
              element="input"
              id="name"
              type="text"
              name="name"
              label="Your Name"
              validators={[VALIDATOR_REQUIRE()]}
              errorText="Please enter a name."
              defaultValue={profile.name}
              onInput={handleInputChange}
            />
            <h3>Instrument(s):</h3>
            <CreatableSelect isClearable isMulti name='instrument' defaultValue={formattedInstruments} onChange={handleInputChange}/>
            <Input
              element="input"
              id="city"
              name="city"
              type="text"
              label="What City in Washington State do you reside in"
              validators={[VALIDATOR_CITY()]}
              errorText="Please enter a valid Washington City."
              defaultValue={profile.city}
              onInput={handleInputChange}
            />
            <h4>Select Playing Level</h4>
            <Select
              element="select"
              id="Playing Level"
              name="playingLevel"
              type="text"
              defaultValue={options[selectIndex]}
              validators={[VALIDATOR_REQUIRE()]}
              errorText="Please select a playing level."
              options={options}
              onInput={handleInputChange}
            />
            <h4>Select how frequently you're hoping to meet</h4>
            <Select
              element="select"
              id="frequency"
              name="frequency"
              type="text"
              defaultValue={frequencyOptions[selectFreqIndex]}
              validators={[VALIDATOR_REQUIRE()]}
              errorText="Please select the frequency you'd like to meet."
              options={frequencyOptions}
              onInput={handleInputChange}
            />
          <Input 
           id="bio"
           name="bio"
           rows = "5"
           type="bio"
           label="Tell us about yourself as a musician..."
           validators={[VALIDATOR_REQUIRE()]}
           isValid={true}
           defaultValue={profile.bio}
           onInput={handleInputChange}
           >
          </Input>
          <h4>Select or type: You are Interested in playing for...</h4>
          <CreatableSelect isClearable isMulti name='interestedIn' options={interestIdeas} defaultValue={formattedInterested} onChange={handleInputChange}/>
          <h4>Select or type: Music Styles you like to play...</h4>
          <CreatableSelect isClearable isMulti name='musicStyle' options={musicStyleIdeas} defaultValue={formattedMusicStyle}  onChange={handleInputChange}/>
          <h4>Select or type: What kind of chamber group/players are you are looking for...</h4>
          <CreatableSelect isClearable isMulti name='lookingFor' options={lookingForIdeas} defaultValue={formattedLookingFor} onChange={handleInputChange}/>
          <Input
            element="input"
            id="email"
            name="email"
            type="email"
            label="E-Mail"
            validators={[VALIDATOR_EMAIL()]}
            errorText="Please enter a valid email address."
            defaultValue={profile.email}
            onInput={handleInputChange}
            />
            <label>
              Toggle:
              <ToggleSwitch checked={checked} onChange={handleToggleChange} />
            </label>
            <input type="hidden" name="isActive" value={checked ? 'true' : 'false'} />
            <p>Select Save Changes to keep any changes made</p>
            <Button type="submit" disabled={!isFormChanged || isSubmitting} onChange={handleFormSubmit}>
            {isSubmitting ? 'Submiting...' : 'Save Changes'}
          </Button>
        </Form>
      </Card>
    </>
  );
};

export default ProfileForm;